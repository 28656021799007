import React from "react";
import { PageWrapper } from "../Common";
import { CreatedPageProps, PortfolioPageContext } from "../../types/portfolio";
import { portfolio_items } from "../../constants/portfolio-items";

type Props = CreatedPageProps<PortfolioPageContext>;

const PorfolioItems = () => (
  <>
    {Object.entries(portfolio_items).map(([path, item]) => (
      <div className="portfolio-item">
        <h2>{item.name}</h2>
        <div>{item.description}</div>
      </div>
    ))}
  </>
);

export const PortfolioPage: React.FC<Props> = ({ pageContext }) => (
  <PageWrapper>
    <PorfolioItems />
  </PageWrapper>
);

// Export for commonjs.
export default PortfolioPage;
