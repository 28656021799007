import { PortfolioPageContext } from "../types/portfolio";

// List of portfolio items, used to enumerate and generate pages.

export const portfolio_items: PortfolioPageContext = {
  luminultra: {
    name: "LuminUltra",
    description: "Some text here!",
  },
  atrinspection: {
    name: "American Tank Robotics",
    description: "A dead simple website.",
  },
};

// For import in commonjs.
export default portfolio_items;
